import angular from 'angular'

function controller($store, $attrs, $loader, $scope, SelectedItemService) {
  const ctrl = this

  ctrl.store = $store
  ctrl.loaded = false

  const watchers = {
    carrier: (nV) => {
      if (nV) ctrl.store.hardwareOrder.carrier_id = nV
    },
  }

  $scope.ctrl = ctrl
  $scope.$watch('ctrl.store.searchFilters.carrier_id', watchers.carrier)
  ctrl.hardwareOrderNoNumber = SelectedItemService.getHardwareOrderNoNumber

  // this method here (not in searchCtrl) because we need display products
  // for existing users and inventory devices
  // for guests
  // depends on hardwareOrder attributes
  // searchCtrl doesn't have access to hardwareOrder
  const setCarrierId = (carrierId = null) => {
    if (carrierId) ctrl.store.searchFilters.carrier_id = carrierId
  }

  const enableNewDeviceOrderTab = (carrierId = null) => {
    ctrl.store.searchFilters.enableNewDeviceOrderTab()
    ctrl.store.preselectMode()
    return setCarrierId(carrierId)
  }

  const enableOrderWithoutNumberTab = (carrierId = null) => {
    ctrl.store.searchFilters.enableOrderWithoutNumberTab()
    return setCarrierId(carrierId)
  }

  const enableHardwareUpgradeTab = (carrierId = null) => {
    ctrl.store.searchFilters.enableHardwareUpgradeTab()
    return setCarrierId(carrierId)
  }

  const enableAccessoriesTab = (carrierId = null) => {
    ctrl.store.searchFilters.enableAccessoriesTab()
    return setCarrierId(carrierId)
  }

  const isNewDeviceOrderTab = () => ctrl.store.searchFilters.isNewDeviceOrderTab()
  const isHardwareUpgradeTab = () => ctrl.store.searchFilters.isHardwareUpgradeTab()
  const isAccessoriesTab = () => ctrl.store.searchFilters.isAccessoriesTab()
  const isWithoutNumberTab = () => ctrl.store.searchFilters.isWithoutNumberTab()

  const showSuspendedLines = (orderedItem) => {
    ctrl.store.selectItem(orderedItem)
    return ctrl.store.searchFilters.enableSuspendedLinesMode()
  }

  const sendHardwareOrder = () => {
    ctrl.loaded = false
    // because for singular orders I add nothing to ordered items I need to prepare order before send
    // yes, it's a hack.
    if (!ctrl.store.hardwareOrder.isMixed()) {
      ctrl.store.hardwareOrder.ordered_items.push(ctrl.store.selectedItem)
    }
    return $loader.send(ctrl.store.hardwareOrder).then((order) => (location.href = order.url))
  }

  const loadOrder = () =>
    $loader.get($attrs.path).then((order) => {
      ctrl.store.setOrder(order)
      ctrl.loaded = true
      $scope.$digest()
    })

  loadOrder()

  ctrl.enableHardwareUpgradeTab = enableHardwareUpgradeTab
  ctrl.enableAccessoriesTab = enableAccessoriesTab
  ctrl.enableNewDeviceOrderTab = enableNewDeviceOrderTab
  ctrl.enableOrderWithoutNumberTab = enableOrderWithoutNumberTab

  ctrl.isAccessoriesTab = isAccessoriesTab
  ctrl.isHardwareUpgradeTab = isHardwareUpgradeTab
  ctrl.isNewDeviceOrderTab = isNewDeviceOrderTab
  ctrl.isWithoutNumberTab = isWithoutNumberTab

  ctrl.showSuspendedLines = showSuspendedLines
  ctrl.sendHardwareOrder = sendHardwareOrder

  return ctrl
}

controller.$inject = [
  '$hwStore',
  '$attrs',
  '$hwHardwareOrderLoader',
  '$scope',
  '$element',
  'SelectedItemService',
]

angular.module('argon.hardwareOrdering').controller('hwStoreCtrl', controller)
