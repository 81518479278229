import angular from 'angular'

const module = angular.module('argon.hardwareOrdering')
module.factory('SelectedItemService', function () {
  let hardwareOrderNoNumber = false
  const service = {
    getHardwareOrderNoNumber: function () {
      return hardwareOrderNoNumber
    },
    setHardwareOrderNoNumber: function () {
      hardwareOrderNoNumber = true
    },
  }
  return service
})
