import angular from 'angular'
import { every, values, isEmpty, each, keys } from 'lodash-es'
import { OrderItem } from '../models/order_item'

angular.module('ArgonModule').controller('OrderItemsCtrl', [
  '$scope',
  '$element',
  function ($scope, $element) {
    $scope.form = {
      isForNewUser: () => $scope.form.mode == 'new_user',
      isForNoNumber: () => $scope.form.mode == 'outright',
      isForUpgrade: () => $scope.form.mode == 'existing_user',
      isForAccessory: () => $scope.form.mode == 'accessory',
    }

    $scope.useItem = (item) => {
      $scope.orderItem = new OrderItem(item)
      if ($element.data('orderNoNumber')) {
        $scope.orderItem.noNumber = $element.data('orderNoNumber')
      }
    }

    // Because we want track changes only after real initialization.
    angular.element(document).ready(() => {
      $scope.$watch('orderItem.employee_contact', (newValue, oldValue) => {
        if (newValue !== oldValue) setEmployeeFields(newValue)
      })

      // We want have chance change username and line should not change it on init.
      // https://github.com/SimplexMobility/argon.simplexmobility.com/issues/1725
      // I can't remove this code because accessory only items still have possibility to change
      // business units...
      // Anyway need to refactore.
      $scope.$watch('orderItem.line', (nV, oV) => {
        const new_id = nV?.id
        const old_id = oV?.id
        // if old_id blank but current_username present - it's initialization.
        const has_changes = old_id && new_id !== old_id

        if (!$scope.orderItem.username || has_changes) $scope.orderItem.username = nV?.username

        if (!$scope.orderItem.number || has_changes) $scope.orderItem.number = nV?.number

        if (!$scope.orderItem.employee_email || has_changes)
          $scope.orderItem.employee_email = nV?.email

        if (!$scope.orderItem.employee_number || has_changes)
          $scope.orderItem.employee_number = nV?.employee_number

        if (!$scope.orderItem.device_model_id || has_changes)
          $scope.orderItem.device_model_id = nV?.device_model_id || ''

        if (!$scope.orderItem.device_id || has_changes) chooseDefaultAccountingCodes(nV)
      })
    })

    const chooseDefaultAccountingCodes = (device) => {
      if (device) $scope.orderItem.accounting_codes = device.business_units
    }

    const clearAccountingCodes = () => {
      for (let type_name in $scope.orderItem.accounting_codes) {
        $scope.orderItem.accounting_codes[type_name] = ''
      }
    }

    const allAccountingCodesFilled = () =>
      every(values($scope.orderItem.accounting_codes), (v) => !isEmpty(v))

    const setEmployeeFields = (employee) => {
      const newFields = employee || {}
      if (!newFields.accounting_codes) newFields.accounting_codes = {}
      if (!newFields.manager_ids) newFields.manager_ids = []

      // set or clear values...
      $scope.orderItem.username = newFields.name || ''
      $scope.orderItem.employee_number = newFields.employee_number || ''
      $scope.orderItem.employee_email = newFields.email || ''
      $scope.orderItem.city = newFields.address || ''
      $scope.orderItem.manager_id = newFields.manager_ids[0] || $scope.orderItem.default_manager_id
      clearAccountingCodes()

      for (let type_name in employee.accounting_codes) {
        const category_name = employee.accounting_codes[type_name]
        $scope.orderItem.accounting_codes[type_name] = category_name
      }

      if (employee) $scope.orderItem.accounting_codes_status = 'entered'

      // Reset all business unit fields to 'exist' mode
      if (allAccountingCodesFilled()) {
        if (!$scope.orderItem.accounting_codes_mode) $scope.orderItem.accounting_codes_mode = {}
        each(
          keys($scope.orderItem.accounting_codes_mode),
          (key) => ($scope.orderItem.accounting_codes_mode[key] = 'exist')
        )
      }
    }
  },
])
