import { debounce } from 'lodash-es'

const initUI = () => {
  // That sad but true
  // We have two initializers - one for class=datepicker and one for role=datepicker
  // see base.js document.on(ready)

  // Use <div class="datepicker js-bootstrap-disable"> for disable bootstrap datepicker

  $('.datepicker:not(.js-bootstrap-disable)').datepicker({
    format: 'yyyy-mm-dd',
    autoclose: true,
    todayHighlight: true,
  })

  // For fluid containers
  $('.datatable:not(.dataTable)').each(function () {
    window.activateDataTable(this)
  })

  $('.js_approval_mappings_datatable:not(.dataTable)').dataTable({
    bInfo: false,
    bLengthChange: false,
    bPaginate: false,
    bRetrieve: true,
  })

  $('.data_usage:not(.dataTable)').dataTable({
    aaSorting: [[5, 'desc']],
    iDisplayLength: 25,
    bRetrieve: true,
  })

  // TODO: We have a few different initializers for select2.
  // see base.js document.on(ready)
  $('select.select2').each(function () {
    const $el = $(this)
    let options = {}
    if ($el.data('select2-disable-search')) {
      options.minimumResultsForSearch = -1
    }
    const args = $el.data('select2-options')
    if (args && typeof args == 'object') {
      options = $.extend(options, args)
    }
    $el.select2(options)
  })

  $('.has-popover').each(function () {
    $(this).trigger('argon:createPopover')
  })

  $('@popover').each(function () {
    $(this).trigger('argon:createPopover')
  })

  $('.f-dropdown[data-dropdown-content]').appendTo($('body'))

  // TODO: looks like it will triggered on the full document
  // if data-replace elements not found.
  // so any ajax:success will trigger 'ajax:replaced'
  $('[data-remote][data-replace]')
    .data('type', 'html')
    .on('ajax:success', function (event, data) {
      const $this = $(this)
      $($this.data('replace')).html(data)
      $this.trigger('ajax:replaced')
    })
}

// Use _.debounce in order to reduce `initUI` calls
$(document).on(
  'init.argon.ui',
  debounce((event) => initUI(event.target), 250)
)
