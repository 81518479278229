import angular from 'angular'
import { find, pull, sumBy, toNumber } from 'lodash-es'

// i need a some methods for ordered items
// base for selected item really
class Item {
  static new(attributes) {
    return new Item(attributes)
  }

  // didn't have a category because
  // I don't want display 'Add to Cart' for the unready item.
  constructor(attributes = {}) {
    for (const attr in attributes) {
      this[attr] = attributes[attr]
    }

    if (!this.accessories) this.accessories = []
    if (!this.human_state) this.human_state = 'new'
    if (!this.total_cost) this.total_cost = 0

    if (!this.order) console.error('Each Item should have link to order!')
  }

  isForNewUser() {
    if (this.category == 'new') return true
  }

  isForOutrightPurchase() {
    if (this.category == 'outright') return true
  }

  isHardwareUpgrade() {
    if (this.category == 'replacement') return true
    // because this kind of orders have device too sometimes.
    // if after order creation device will select for business units.
    // and then user return back to store.
    if (this.isAccessoryOnly()) return false
    return this.device != null
  }

  isAccessoryOnly() {
    if (this.category == 'accessory') return true
    if (this.permitted_product) return
    if (this.inventory_device) return
    if (this.device) return
    return this.hasAccessories()
  }

  isReady() {
    if (this.inventory_device) {
      return true
    } else if (this.permitted_product) {
      return this.term ? true : false
    } else if (this.hasAccessories()) {
      return true
    }
  }

  isBlank() {
    if (this.permitted_product) return
    if (this.inventory_device) return
    if (this.device) return
    if (this.hasAccessories()) return
    return true
  }

  needApproval() {
    return this.id || this.category
  }

  setProduct(product, term) {
    this.inventory_device = null
    this.permitted_product = product
    this.term = term

    if (product) {
      this.category = this.device ? 'replacement' : 'new'
      if (this.outright) {
        this.category = 'outright'
      }
      if (!this.color) {
        this.color = product.colors?.[0]
      }
    } else {
      this.clearAccessories()
      if (!(this.id || this.device)) this.category = null
      this.color = null
    }
  }

  setInventoryDevice(inventory_device) {
    this.permitted_product = null
    this.inventory_device = inventory_device

    if (inventory_device) {
      this.category = this.device ? 'replacement' : 'new'
    } else {
      this.clearAccessories()
      if (!(this.id || this.device)) this.category = null
    }
  }

  setLine(line) {
    this.device = line

    this.category = this.device ? 'replacement' : null
  }

  addAccessory(accessory) {
    accessory.readonly = 1
    accessory.quantity = 1
    // existing items has accessories with estimate cost when in store accessories has only cost.
    accessory.estimated_cost = accessory.cost
    // for custom
    if (!accessory.estimated_dealer_cost) accessory.estimated_dealer_cost = accessory.cost
    this.accessories.push(accessory)
    if (!this.category) this.category = 'accessory'
  }

  hasAccessory(id) {
    return find(this.accessories, { id: id })
  }

  removeAccessory(accessory) {
    pull(this.accessories, accessory)

    if (this.category == 'accessory' && this.accessories.length == 0) {
      if (!this.id) this.category = null
    }
  }

  clearAccessories() {
    this.accessories = []
  }

  totalCost() {
    let result = 0
    if (this.term?.price) result += this.term.price
    result += sumBy(
      this.accessories,
      (accessory) => accessory.quantity * toNumber(accessory.estimated_cost)
    )
    return result
  }

  replacedDevice() {
    return this.isHardwareUpgrade() && this.device != null
  }

  orderOnlyAccessories() {
    this.permitted_product = null
    this.inventory_device = null
    this.term = null
    this.color = null
    this.total_cost = this.totalCost()
    this.category = 'accessory'
    this.tab = 'accessory'
  }

  hasAccessories() {
    return this.accessories.length > 0
  }

  products() {
    return this.permitted_product ? [this.permitted_product] : []
  }

  actualDeviceModel() {
    if (this.permitted_product) {
      return {
        device_model_id: this.permitted_product.device_model_id || this.device_model_id,
        device_make_id: this.permitted_product.device_make_id,
      }
    } else if (this.inventory_device) {
      return {
        device_model_id: this.inventory_device.device_model_id,
        device_make_id: this.inventory_device.device_make_id,
      }
    } else {
      return null
    }
  }

  attributes() {
    const result = {
      category: this.category,
      product_id: null,
      inventory_id: null,
      accessories: this.accessories,
    }

    if (this.id) result.id = this.id

    if (this.isHardwareUpgrade()) result.device_id = this.device.id

    if (this.permitted_product) {
      result.product_id = this.permitted_product.id
      // There is a problem here - we update device model id on edit order...

      if (result.product_id)
        result.device_model_id = this.permitted_product.device_model_id || this.device_model_id
      else result.new_product_device_model_id = this.permitted_product.device_model_id

      result.plan = this.term.name
      result.subclient_estimate_product_price = this.term.price
      result.color = this.color
    }

    if (this.inventory_device) {
      result.inventory_id = this.inventory_device.id
    }

    return result
  }
}

angular.module('argon.hardwareOrdering').factory('$hwItemFactory', function () {
  return { new: Item.new }
})
